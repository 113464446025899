import * as Sentry from "@sentry/browser"
import {Integrations} from "@sentry/tracing"

Sentry.init({
  dsn: "https://94921c9349b3468c9b148f121ee55cc7@o89869.ingest.sentry.io/5688189",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.0,
  environment: process.env.RAILS_ENV
})
